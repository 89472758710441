.root {
  background: linear-gradient(227deg, #f9c9ec 0%, #e1d8f6 100%) 0% 0% / 100% 100% repeat;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  position: relative;
  min-width: 0;
  padding: 30px;
}
@media (min-width: 768px) {
  .root {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    background: none;
    flex-row-gap: 0px;
    min-width: 0;
  }
}
.rootisScrolled {
  padding-top: 15px;
  padding-bottom: 15px;
}
.freeBox__kygE {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .freeBox__kygE {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.slotTargetTitle {
  font-size: 38px;
  font-weight: 800;
  line-height: 1.2;
}
.slotTargetTitleisScrolled {
  font-size: 16px;
}
.slotTargetTitleisScrolled > *,
.slotTargetTitleisScrolled > .__wab_slot > *,
.slotTargetTitleisScrolled > .__wab_slot > .__wab_slot > *,
.slotTargetTitleisScrolled > .__wab_slot > .__wab_slot > .__wab_slot > *,
.slotTargetTitleisScrolled > picture > img,
.slotTargetTitleisScrolled > .__wab_slot > picture > img,
.slotTargetTitleisScrolled > .__wab_slot > .__wab_slot > picture > img,
.slotTargetTitleisScrolled > .__wab_slot > .__wab_slot > .__wab_slot > picture > img {
  display: none;
}
.freeBox__cNoaG {
  display: block;
  position: relative;
  height: 8px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.freeBoxisScrolled__cNoaGWgLjy {
  height: 2px;
  flex-shrink: 0;
  display: none;
}
.freeBox__padB7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.slotTargetName {
  font-size: 22px;
}
@media (min-width: 768px) {
  .slotTargetName {
    font-size: 24px;
  }
}
.slotTargetNameisScrolled {
  font-size: 16px;
}
.slotTargetDivider {
  font-size: 22px;
}
@media (min-width: 768px) {
  .slotTargetDivider {
    font-size: 24px;
  }
}
.slotTargetDividerisScrolled {
  font-size: 16px;
}
.slotTargetBirthdate {
  font-size: 22px;
}
@media (min-width: 768px) {
  .slotTargetBirthdate {
    font-size: 24px;
  }
}
.slotTargetBirthdateisScrolled {
  font-size: 16px;
}
.freeBox__dR5Aj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.slotTargetTestedAt {
  font-size: 22px;
}
@media (min-width: 768px) {
  .slotTargetTestedAt {
    font-size: 24px;
  }
}
.slotTargetTestedAtisScrolled {
  font-size: 16px;
}
.slotTargetDate {
  font-size: 22px;
}
@media (min-width: 768px) {
  .slotTargetDate {
    font-size: 24px;
  }
}
.slotTargetDateisScrolled {
  font-size: 16px;
}
.freeBox__n7ZsP {
  display: block;
  position: relative;
  height: 16px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .freeBox__n7ZsP {
    width: auto;
    display: none;
  }
}
.freeBoxnoButton__n7ZsPEbpuK {
  display: none;
}
.button:global(.__wab_instance) {
  flex-shrink: 0;
}
.buttonisScrolled:global(.__wab_instance) {
  flex-shrink: 0;
}
.buttonnoButton:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.svg__mlybp {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  display: block;
}
.text {
  height: auto;
}
.svg___6UEq {
  position: relative;
  width: 1em;
  height: 1em;
}
