.root {
  flex-direction: row;
  display: flex;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.rootisCard {
  background: var(--token-b5_F3MaWO);
  box-shadow: 0px 40px 33px 2px #0000001a, 0px 22px 18px 0px #00000014, 0px 13px 12px 0px #0000000f;
  max-width: 530px;
  border-radius: 2px;
  padding: 100px 60px;
}
.passCodeLetter__vjH8V:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
.passCodeLetter__z7QF:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
.passCodeLetter__a2L4I:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
.passCodeLetter__xac1I:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
.passCodeLetter__yM8CL:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
.passCodeLetter__cov6L:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
