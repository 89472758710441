.plasmic_tokens {
  --token-OU7EuAwtS: #ffffff;
  --plasmic-token-white: var(--token-OU7EuAwtS);
  --token-b5_F3MaWO: #000000;
  --plasmic-token-black: var(--token-b5_F3MaWO);
  --token-Fsnegb8P1: #000000cc;
  --plasmic-token-black-080-a: var(--token-Fsnegb8P1);
  --token-MNTSAZsue: #000000b3;
  --plasmic-token-black-070-a: var(--token-MNTSAZsue);
  --token-bFu8w2vD5: #00000099;
  --plasmic-token-black-060-a: var(--token-bFu8w2vD5);
  --token-jh2dYQAkV: #00000080;
  --plasmic-token-black-050-a: var(--token-jh2dYQAkV);
  --token-Ajlopyo3r: #0000004d;
  --plasmic-token-black-030-a: var(--token-Ajlopyo3r);
  --token-RCoLngxgW: #00000033;
  --plasmic-token-black-020-a: var(--token-RCoLngxgW);
  --token-Hk6dmR-vw: #0000001a;
  --plasmic-token-black-010-a: var(--token-Hk6dmR-vw);
  --token-qsn-fdcEb: #0000000d;
  --plasmic-token-black-005-a: var(--token-qsn-fdcEb);
  --token-D91PB6BeW: #083e07;
  --plasmic-token-green-900: var(--token-D91PB6BeW);
  --token-Hz8pOMy6h: #208e1d;
  --plasmic-token-green-600: var(--token-Hz8pOMy6h);
  --token-hRLHaBBAu: #03a913;
  --plasmic-token-green-500: var(--token-hRLHaBBAu);
  --token-zXwl8rfLR: #affab6;
  --plasmic-token-green-200: var(--token-zXwl8rfLR);
  --token-rCc8cTgDd: #dcfee0;
  --plasmic-token-green-100: var(--token-rCc8cTgDd);
  --token-_CQhxCwqn: #1a9ebd;
  --plasmic-token-blue-500: var(--token-_CQhxCwqn);
  --token-pkeGMzUnV: #cb4945;
  --plasmic-token-red-600: var(--token-pkeGMzUnV);
  --token-7Vqfz2c-_: #f35850;
  --plasmic-token-red-500: var(--token-7Vqfz2c-_);
  --token-GgOLx8cI6: #fdc6c3;
  --plasmic-token-red-200: var(--token-GgOLx8cI6);
  --token-GNfbzhOHC: #fededc;
  --plasmic-token-red-100: var(--token-GNfbzhOHC);
  --token-WXVuPScU2: #ce8728;
  --plasmic-token-orange-600: var(--token-WXVuPScU2);
  --token-2fQL8fvQI: #f5a21d;
  --plasmic-token-orange-500: var(--token-2fQL8fvQI);
  --token-_WJqnmkDn: #fee09d;
  --plasmic-token-orange-200: var(--token-_WJqnmkDn);
  --token-vAu4MJY3s: #ffecc1;
  --plasmic-token-orange-100: var(--token-vAu4MJY3s);
}

.plasmic_default_styles {
  --mixin-Y7IbhePGQG_font-family: 'Inter';
  --mixin-Y7IbhePGQG_font-size: 17px;
  --mixin-Y7IbhePGQG_font-weight: 400;
  --mixin-Y7IbhePGQG_font-style: normal;
  --mixin-Y7IbhePGQG_color: var(--token-b5_F3MaWO);
  --mixin-Y7IbhePGQG_text-align: left;
  --mixin-Y7IbhePGQG_line-height: 1.4;
  --mixin-Y7IbhePGQG_letter-spacing: normal;
  --mixin-Y7IbhePGQG_white-space: pre-wrap;
  --mixin-Y7IbhePGQG_user-select: text;
  --mixin-Y7IbhePGQG_text-decoration-line: none;
  --mixin-Y7IbhePGQG_text-overflow: clip;
  --mixin-Y7IbhePGQG_text-transform: none;
  --mixin-gYgjAOuQs_font-family: 'Inter';
  --mixin-gYgjAOuQs_color: #000000;
  --mixin-gYgjAOuQs_font-size: 72px;
  --mixin-gYgjAOuQs_font-weight: 900;
  --mixin-gYgjAOuQs_letter-spacing: -4px;
  --mixin-gYgjAOuQs_line-height: 1;
  --mixin-tkdJw3cTJk_font-family: 'Inter';
  --mixin-tkdJw3cTJk_color: #000000;
  --mixin-tkdJw3cTJk_font-size: 48px;
  --mixin-tkdJw3cTJk_font-weight: 700;
  --mixin-tkdJw3cTJk_letter-spacing: -1px;
  --mixin-tkdJw3cTJk_line-height: 1.1;
  --mixin-q12MRRMwLw_font-family: 'Inter';
  --mixin-q12MRRMwLw_color: #000000;
  --mixin-q12MRRMwLw_font-size: 32px;
  --mixin-q12MRRMwLw_font-weight: 600;
  --mixin-q12MRRMwLw_letter-spacing: -0.8px;
  --mixin-q12MRRMwLw_line-height: 1.2;
  --mixin-znBydlI8-A_font-family: 'Inter';
  --mixin-znBydlI8-A_color: #000000;
  --mixin-znBydlI8-A_font-size: 24px;
  --mixin-znBydlI8-A_font-weight: 600;
  --mixin-znBydlI8-A_letter-spacing: -0.5px;
  --mixin-znBydlI8-A_line-height: 1.3;
  --mixin-bXm_untHHy_font-family: 'Inter';
  --mixin-bXm_untHHy_color: #000000;
  --mixin-bXm_untHHy_font-size: 20px;
  --mixin-bXm_untHHy_font-weight: 600;
  --mixin-bXm_untHHy_letter-spacing: -0.3px;
  --mixin-bXm_untHHy_line-height: 1.5;
  --mixin-NsRc53UxEo_font-family: 'Inter';
  --mixin-NsRc53UxEo_color: #000000;
  --mixin-NsRc53UxEo_font-size: 16px;
  --mixin-NsRc53UxEo_font-weight: 600;
  --mixin-NsRc53UxEo_line-height: 1.5;
  --mixin-dUYZU4Op-z_border-left-color: #dddddd;
  --mixin-dUYZU4Op-z_border-left-style: solid;
  --mixin-dUYZU4Op-z_border-left-width: 3px;
  --mixin-dUYZU4Op-z_color: #888888;
  --mixin-dUYZU4Op-z_padding-left: 10px;
  --mixin-W4ixghAXWa_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-W4ixghAXWa_border-bottom-color: #dddddd;
  --mixin-W4ixghAXWa_border-bottom-style: solid;
  --mixin-W4ixghAXWa_border-bottom-width: 1px;
  --mixin-W4ixghAXWa_border-left-color: #dddddd;
  --mixin-W4ixghAXWa_border-left-style: solid;
  --mixin-W4ixghAXWa_border-left-width: 1px;
  --mixin-W4ixghAXWa_border-right-color: #dddddd;
  --mixin-W4ixghAXWa_border-right-style: solid;
  --mixin-W4ixghAXWa_border-right-width: 1px;
  --mixin-W4ixghAXWa_border-top-color: #dddddd;
  --mixin-W4ixghAXWa_border-top-style: solid;
  --mixin-W4ixghAXWa_border-top-width: 1px;
  --mixin-W4ixghAXWa_border-bottom-left-radius: 3px;
  --mixin-W4ixghAXWa_border-bottom-right-radius: 3px;
  --mixin-W4ixghAXWa_border-top-left-radius: 3px;
  --mixin-W4ixghAXWa_border-top-right-radius: 3px;
  --mixin-W4ixghAXWa_font-family: 'Inconsolata';
  --mixin-W4ixghAXWa_padding-bottom: 1px;
  --mixin-W4ixghAXWa_padding-left: 4px;
  --mixin-W4ixghAXWa_padding-right: 4px;
  --mixin-W4ixghAXWa_padding-top: 1px;
  --mixin-8RSZU-6pqW_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-8RSZU-6pqW_border-bottom-color: #dddddd;
  --mixin-8RSZU-6pqW_border-bottom-style: solid;
  --mixin-8RSZU-6pqW_border-bottom-width: 1px;
  --mixin-8RSZU-6pqW_border-left-color: #dddddd;
  --mixin-8RSZU-6pqW_border-left-style: solid;
  --mixin-8RSZU-6pqW_border-left-width: 1px;
  --mixin-8RSZU-6pqW_border-right-color: #dddddd;
  --mixin-8RSZU-6pqW_border-right-style: solid;
  --mixin-8RSZU-6pqW_border-right-width: 1px;
  --mixin-8RSZU-6pqW_border-top-color: #dddddd;
  --mixin-8RSZU-6pqW_border-top-style: solid;
  --mixin-8RSZU-6pqW_border-top-width: 1px;
  --mixin-8RSZU-6pqW_border-bottom-left-radius: 3px;
  --mixin-8RSZU-6pqW_border-bottom-right-radius: 3px;
  --mixin-8RSZU-6pqW_border-top-left-radius: 3px;
  --mixin-8RSZU-6pqW_border-top-right-radius: 3px;
  --mixin-8RSZU-6pqW_font-family: 'Inconsolata';
  --mixin-8RSZU-6pqW_padding-bottom: 3px;
  --mixin-8RSZU-6pqW_padding-left: 6px;
  --mixin-8RSZU-6pqW_padding-right: 6px;
  --mixin-8RSZU-6pqW_padding-top: 3px;
  --mixin-9mv_1gsuXb_display: flex;
  --mixin-9mv_1gsuXb_flex-direction: column;
  --mixin-9mv_1gsuXb_align-items: stretch;
  --mixin-9mv_1gsuXb_justify-content: flex-start;
  --mixin-9mv_1gsuXb_list-style-position: outside;
  --mixin-9mv_1gsuXb_padding-left: 40px;
  --mixin-9mv_1gsuXb_position: relative;
  --mixin-9mv_1gsuXb_list-style-type: decimal;
  --mixin-pLxfppZgEj_display: flex;
  --mixin-pLxfppZgEj_flex-direction: column;
  --mixin-pLxfppZgEj_align-items: stretch;
  --mixin-pLxfppZgEj_justify-content: flex-start;
  --mixin-pLxfppZgEj_list-style-position: outside;
  --mixin-pLxfppZgEj_padding-left: 40px;
  --mixin-pLxfppZgEj_position: relative;
  --mixin-pLxfppZgEj_list-style-type: disc;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_rich_text *),
:where(.__wab_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_rich_text img),
:where(.__wab_text img) {
}

:where(.li) {
  display: list-item;
}
:where(.__wab_rich_text li),
:where(.__wab_text li) {
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_rich_text span),
:where(.__wab_text span) {
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_rich_text input),
:where(.__wab_text input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_rich_text textarea),
:where(.__wab_text textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_rich_text button),
:where(.__wab_text button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_rich_text code),
:where(.__wab_text code) {
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_rich_text pre),
:where(.__wab_text pre) {
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_rich_text p),
:where(.__wab_text p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_rich_text h1),
:where(.__wab_text h1) {
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_rich_text h2),
:where(.__wab_text h2) {
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_rich_text h3),
:where(.__wab_text h3) {
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_rich_text h4),
:where(.__wab_text h4) {
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_rich_text h5),
:where(.__wab_text h5) {
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_rich_text h6),
:where(.__wab_text h6) {
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_rich_text address),
:where(.__wab_text address) {
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_rich_text a),
:where(.__wab_text a) {
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_rich_text ol),
:where(.__wab_text ol) {
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_rich_text ul),
:where(.__wab_text ul) {
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_rich_text select),
:where(.__wab_text select) {
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.root_reset {
  font-family: var(--mixin-Y7IbhePGQG_font-family);
  font-size: var(--mixin-Y7IbhePGQG_font-size);
  font-weight: var(--mixin-Y7IbhePGQG_font-weight);
  font-style: var(--mixin-Y7IbhePGQG_font-style);
  color: var(--mixin-Y7IbhePGQG_color);
  text-align: var(--mixin-Y7IbhePGQG_text-align);
  text-transform: var(--mixin-Y7IbhePGQG_text-transform);
  line-height: var(--mixin-Y7IbhePGQG_line-height);
  letter-spacing: var(--mixin-Y7IbhePGQG_letter-spacing);
  white-space: var(--mixin-Y7IbhePGQG_white-space);
}

:where(.root_reset .plasmic_default__h1),
:where(.root_reset .h1),
:where(.root_reset.plasmic_default__h1) {
  font-family: var(--mixin-gYgjAOuQs_font-family);
  color: var(--mixin-gYgjAOuQs_color);
  font-size: var(--mixin-gYgjAOuQs_font-size);
  font-weight: var(--mixin-gYgjAOuQs_font-weight);
  letter-spacing: var(--mixin-gYgjAOuQs_letter-spacing);
  line-height: var(--mixin-gYgjAOuQs_line-height);
}

:where(.root_reset .plasmic_default__h2),
:where(.root_reset .h2),
:where(.root_reset.plasmic_default__h2) {
  font-family: var(--mixin-tkdJw3cTJk_font-family);
  color: var(--mixin-tkdJw3cTJk_color);
  font-size: var(--mixin-tkdJw3cTJk_font-size);
  font-weight: var(--mixin-tkdJw3cTJk_font-weight);
  letter-spacing: var(--mixin-tkdJw3cTJk_letter-spacing);
  line-height: var(--mixin-tkdJw3cTJk_line-height);
}

:where(.root_reset .plasmic_default__h3),
:where(.root_reset .h3),
:where(.root_reset.plasmic_default__h3) {
  font-family: var(--mixin-q12MRRMwLw_font-family);
  color: var(--mixin-q12MRRMwLw_color);
  font-size: var(--mixin-q12MRRMwLw_font-size);
  font-weight: var(--mixin-q12MRRMwLw_font-weight);
  letter-spacing: var(--mixin-q12MRRMwLw_letter-spacing);
  line-height: var(--mixin-q12MRRMwLw_line-height);
}

:where(.root_reset .plasmic_default__h4),
:where(.root_reset .h4),
:where(.root_reset.plasmic_default__h4) {
  font-family: var(--mixin-znBydlI8-A_font-family);
  color: var(--mixin-znBydlI8-A_color);
  font-size: var(--mixin-znBydlI8-A_font-size);
  font-weight: var(--mixin-znBydlI8-A_font-weight);
  letter-spacing: var(--mixin-znBydlI8-A_letter-spacing);
  line-height: var(--mixin-znBydlI8-A_line-height);
}

:where(.root_reset .plasmic_default__h5),
:where(.root_reset .h5),
:where(.root_reset.plasmic_default__h5) {
  font-family: var(--mixin-bXm_untHHy_font-family);
  color: var(--mixin-bXm_untHHy_color);
  font-size: var(--mixin-bXm_untHHy_font-size);
  font-weight: var(--mixin-bXm_untHHy_font-weight);
  letter-spacing: var(--mixin-bXm_untHHy_letter-spacing);
  line-height: var(--mixin-bXm_untHHy_line-height);
}

:where(.root_reset .plasmic_default__h6),
:where(.root_reset .h6),
:where(.root_reset.plasmic_default__h6) {
  font-family: var(--mixin-NsRc53UxEo_font-family);
  color: var(--mixin-NsRc53UxEo_color);
  font-size: var(--mixin-NsRc53UxEo_font-size);
  font-weight: var(--mixin-NsRc53UxEo_font-weight);
  line-height: var(--mixin-NsRc53UxEo_line-height);
}

:where(.root_reset .plasmic_default__blockquote),
:where(.root_reset .blockquote),
:where(.root_reset.plasmic_default__blockquote) {
  color: var(--mixin-dUYZU4Op-z_color);
  padding-left: var(--mixin-dUYZU4Op-z_padding-left);
  border-left: var(--mixin-dUYZU4Op-z_border-left-width) var(--mixin-dUYZU4Op-z_border-left-style)
    var(--mixin-dUYZU4Op-z_border-left-color);
}

:where(.root_reset .plasmic_default__code),
:where(.root_reset .code),
:where(.root_reset.plasmic_default__code) {
  background: var(--mixin-W4ixghAXWa_background);
  font-family: var(--mixin-W4ixghAXWa_font-family);
  border-radius: var(--mixin-W4ixghAXWa_border-top-left-radius) var(--mixin-W4ixghAXWa_border-top-right-radius)
    var(--mixin-W4ixghAXWa_border-bottom-right-radius) var(--mixin-W4ixghAXWa_border-bottom-left-radius);
  padding: var(--mixin-W4ixghAXWa_padding-top) var(--mixin-W4ixghAXWa_padding-right)
    var(--mixin-W4ixghAXWa_padding-bottom) var(--mixin-W4ixghAXWa_padding-left);
  border-top: var(--mixin-W4ixghAXWa_border-top-width) var(--mixin-W4ixghAXWa_border-top-style)
    var(--mixin-W4ixghAXWa_border-top-color);
  border-right: var(--mixin-W4ixghAXWa_border-right-width) var(--mixin-W4ixghAXWa_border-right-style)
    var(--mixin-W4ixghAXWa_border-right-color);
  border-bottom: var(--mixin-W4ixghAXWa_border-bottom-width) var(--mixin-W4ixghAXWa_border-bottom-style)
    var(--mixin-W4ixghAXWa_border-bottom-color);
  border-left: var(--mixin-W4ixghAXWa_border-left-width) var(--mixin-W4ixghAXWa_border-left-style)
    var(--mixin-W4ixghAXWa_border-left-color);
}

:where(.root_reset .plasmic_default__pre),
:where(.root_reset .pre),
:where(.root_reset.plasmic_default__pre) {
  background: var(--mixin-8RSZU-6pqW_background);
  font-family: var(--mixin-8RSZU-6pqW_font-family);
  border-radius: var(--mixin-8RSZU-6pqW_border-top-left-radius) var(--mixin-8RSZU-6pqW_border-top-right-radius)
    var(--mixin-8RSZU-6pqW_border-bottom-right-radius) var(--mixin-8RSZU-6pqW_border-bottom-left-radius);
  padding: var(--mixin-8RSZU-6pqW_padding-top) var(--mixin-8RSZU-6pqW_padding-right)
    var(--mixin-8RSZU-6pqW_padding-bottom) var(--mixin-8RSZU-6pqW_padding-left);
  border-top: var(--mixin-8RSZU-6pqW_border-top-width) var(--mixin-8RSZU-6pqW_border-top-style)
    var(--mixin-8RSZU-6pqW_border-top-color);
  border-right: var(--mixin-8RSZU-6pqW_border-right-width) var(--mixin-8RSZU-6pqW_border-right-style)
    var(--mixin-8RSZU-6pqW_border-right-color);
  border-bottom: var(--mixin-8RSZU-6pqW_border-bottom-width) var(--mixin-8RSZU-6pqW_border-bottom-style)
    var(--mixin-8RSZU-6pqW_border-bottom-color);
  border-left: var(--mixin-8RSZU-6pqW_border-left-width) var(--mixin-8RSZU-6pqW_border-left-style)
    var(--mixin-8RSZU-6pqW_border-left-color);
}

:where(.root_reset .plasmic_default__ol),
:where(.root_reset .ol),
:where(.root_reset.plasmic_default__ol) {
  display: var(--mixin-9mv_1gsuXb_display);
  flex-direction: var(--mixin-9mv_1gsuXb_flex-direction);
  align-items: var(--mixin-9mv_1gsuXb_align-items);
  justify-content: var(--mixin-9mv_1gsuXb_justify-content);
  list-style-position: var(--mixin-9mv_1gsuXb_list-style-position);
  padding-left: var(--mixin-9mv_1gsuXb_padding-left);
  position: var(--mixin-9mv_1gsuXb_position);
  list-style-type: var(--mixin-9mv_1gsuXb_list-style-type);
  flex-column-gap: var(--mixin-9mv_1gsuXb_flex-column-gap);
}

:where(.root_reset .plasmic_default__ul),
:where(.root_reset .ul),
:where(.root_reset.plasmic_default__ul) {
  display: var(--mixin-pLxfppZgEj_display);
  flex-direction: var(--mixin-pLxfppZgEj_flex-direction);
  align-items: var(--mixin-pLxfppZgEj_align-items);
  justify-content: var(--mixin-pLxfppZgEj_justify-content);
  list-style-position: var(--mixin-pLxfppZgEj_list-style-position);
  padding-left: var(--mixin-pLxfppZgEj_padding-left);
  position: var(--mixin-pLxfppZgEj_position);
  list-style-type: var(--mixin-pLxfppZgEj_list-style-type);
  flex-column-gap: var(--mixin-pLxfppZgEj_flex-column-gap);
}
