.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  overflow: visible;
  border-radius: 2px;
}
.button__mPdXt:global(.__wab_instance) {
  position: relative;
}
.svg__y4Ul2 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  display: block;
}
.svg__dwDi {
  position: relative;
  width: 1em;
  height: 1em;
}
.button__mAtsf:global(.__wab_instance) {
  position: relative;
}
.svg__l22Qc {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  display: block;
}
.svg___9Nlwx {
  position: relative;
  width: 1em;
  height: 1em;
}
