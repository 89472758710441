.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.mainNav:global(.__wab_instance) {
  position: relative;
}
.freeBox__jPcOv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 650px;
  align-self: center;
  width: 100%;
  padding: 30px;
}
@media (min-width: 768px) {
  .freeBox__jPcOv {
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
  }
}
.slotTargetTitle {
  font-size: 38px;
  font-weight: 800;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .slotTargetTitle {
    text-align: center;
  }
}
.freeBox__pIzNo {
  display: block;
  position: relative;
  height: 24px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .slotTargetText {
    text-align: center;
  }
}
.freeBox__v36P {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__fv6J5 {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__vrn7S {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.freeBoxstate_error__vrn7SQhJt3 {
  height: 15px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .freeBoxstate_error__vrn7SQhJt3 {
    height: 25px;
    flex-shrink: 0;
  }
}
.freeBox__odRhr {
  flex-direction: row;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  box-shadow: inset 0px 0px 0px 1px var(--token-Hk6dmR-vw);
  border-radius: 6px;
}
.freeBox__odRhr > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .freeBox__odRhr {
    top: auto;
    left: auto;
  }
}
.freeBoxstate_error__odRhrQhJt3 {
  top: auto;
  left: auto;
  background: var(--token-GNfbzhOHC);
  width: auto;
  height: auto;
  padding: 15px;
}
.freeBoxstate_error__odRhrQhJt3 > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxstate_error__odRhrQhJt3 > :global(.__wab_flex-container) > *,
.freeBoxstate_error__odRhrQhJt3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBoxstate_error__odRhrQhJt3 > :global(.__wab_flex-container) > picture > img,
.freeBoxstate_error__odRhrQhJt3 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 10px;
  margin-top: 0px;
}
.text {
  width: 100%;
  min-width: 0;
}
.textstate_error {
  color: var(--token-MNTSAZsue);
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: #000;
  display: block;
  flex-shrink: 0;
}
.svgstate_error {
  color: var(--token-7Vqfz2c-_);
}
.freeBox__gfXyb {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .freeBox__gfXyb {
    height: 60px;
    flex-shrink: 0;
  }
}
