.root {
  flex-direction: column;
  display: flex;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
  margin-top: 6px;
}
@media (min-width: 768px) {
  .root {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) {
    align-content: stretch;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
    margin-top: 0px;
  }
}
.slotTargetTitle {
  font-size: 15px;
  font-weight: 600;
}
.freeBox___6AKr4 {
  flex-direction: row;
  display: flex;
  position: relative;
}
.freeBox___6AKr4 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox___6AKr4 > :global(.__wab_flex-container) > *,
.freeBox___6AKr4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6AKr4 > :global(.__wab_flex-container) > picture > img,
.freeBox___6AKr4 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.freeBox___97MMf {
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  background: var(--token-zXwl8rfLR);
  flex-shrink: 0;
  border-radius: 2px;
}
.freeBox__lJLpG {
  display: flex;
  position: relative;
  height: auto;
}
.slotTargetOkText {
  font-size: 15px;
}
.freeBox__gJwP {
  flex-direction: row;
  display: flex;
  position: relative;
}
.freeBox__gJwP > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__gJwP > :global(.__wab_flex-container) > *,
.freeBox__gJwP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gJwP > :global(.__wab_flex-container) > picture > img,
.freeBox__gJwP > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.freeBox__xI7Vy {
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  background: var(--token-_WJqnmkDn);
  flex-shrink: 0;
  border-radius: 2px;
}
.freeBox___4TjT5 {
  display: flex;
  position: relative;
  height: auto;
}
@media (min-width: 768px) {
  .freeBox___4TjT5 {
    height: auto;
  }
}
.slotTargetCallText {
  font-size: 15px;
}
.freeBox___9R8Vo {
  flex-direction: row;
  display: flex;
  position: relative;
}
.freeBox___9R8Vo > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox___9R8Vo > :global(.__wab_flex-container) > *,
.freeBox___9R8Vo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9R8Vo > :global(.__wab_flex-container) > picture > img,
.freeBox___9R8Vo > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.freeBox__ybjzg {
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  background: var(--token-GgOLx8cI6);
  flex-shrink: 0;
  border-radius: 2px;
}
.freeBox__yfL08 {
  display: flex;
  position: relative;
  height: auto;
}
@media (min-width: 768px) {
  .freeBox__yfL08 {
    height: auto;
  }
}
.slotTargetAppointmentText {
  font-size: 15px;
}
.freeBox__aV8ZX {
  flex-direction: row;
  display: flex;
  position: relative;
}
.freeBox__aV8ZX > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__aV8ZX > :global(.__wab_flex-container) > *,
.freeBox__aV8ZX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aV8ZX > :global(.__wab_flex-container) > picture > img,
.freeBox__aV8ZX > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.freeBox___5M5TT {
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  background: var(--token-Hk6dmR-vw);
  flex-shrink: 0;
  border-radius: 2px;
}
.freeBox__r9X86 {
  display: flex;
  position: relative;
  height: auto;
}
.slotTargetAnalysisPendingText {
  font-size: 15px;
}
