.root {
  position: relative;
  display: flex;
  flex-direction: row;
}
.slotTargetTextValue {
  color: var(--token-Fsnegb8P1);
}
.slotTargetTextValue > .__wab_text,
.slotTargetTextValue > .__wab_slot-string-wrapper,
.slotTargetTextValue > .__wab_slot > .__wab_text,
.slotTargetTextValue > .__wab_slot > .__wab_slot-string-wrapper,
.slotTargetTextValue > .__wab_slot > .__wab_slot > .__wab_text,
.slotTargetTextValue > .__wab_slot > .__wab_slot > .__wab_slot-string-wrapper,
.slotTargetTextValue > .__wab_slot > .__wab_slot > .__wab_slot > .__wab_text,
.slotTargetTextValue > .__wab_slot > .__wab_slot > .__wab_slot > .__wab_slot-string-wrapper {
  text-decoration-line: underline;
}
