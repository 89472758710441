.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-qsn-fdcEb);
  position: relative;
  border-radius: 2px;
}
@media (min-width: 768px) {
  .root {
    width: 300px;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 320px;
  }
}
.rootinterpretation_negative {
  background: var(--token-zXwl8rfLR);
}
.rootinterpretation_positive {
  background: var(--token-GgOLx8cI6);
}
.rootinterpretation_valuesIncreased {
  background: var(--token-_WJqnmkDn);
}
.hoverBg {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  background: transparent;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  border-radius: 2px;
}
.root:hover .hoverBg {
  background: #ffffff66;
}
.freeBox__tt07Y {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  min-height: 0;
  padding: 16px 20px;
}
.freeBoxhasButton__tt07Yvp5Wu {
  padding-bottom: 20px;
}
.freeBox___94Mtw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBox__chuKg {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.freeBox__ap079 {
  display: flex;
  position: relative;
  width: auto;
}
.slotTargetTitle {
  font-size: 22px;
  font-weight: 800;
}
.freeBox__lwq3L {
  flex-direction: row;
  display: flex;
  position: relative;
  margin-top: 3px;
}
.freeBox__lwq3L > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__lwq3L > :global(.__wab_flex-container) > *,
.freeBox__lwq3L > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lwq3L > :global(.__wab_flex-container) > picture > img,
.freeBox__lwq3L > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.svg___7Z7Fo {
  position: relative;
  object-fit: cover;
  color: var(--token-Ajlopyo3r);
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.svginterpretation_negative___7Z7FoIojhX {
  color: var(--token-hRLHaBBAu);
}
.svginterpretation_positive___7Z7Foq4Rkl {
  color: var(--token-7Vqfz2c-_);
}
.svginterpretation_valuesIncreased___7Z7FoxsYHg {
  color: var(--token-2fQL8fvQI);
}
.freeBox__jjZ44 {
  display: flex;
  position: relative;
  width: auto;
}
.slotTargetValue {
  font-size: 18px;
  font-weight: 600;
  color: var(--token-Ajlopyo3r);
}
.slotTargetValueinterpretation_negative {
  color: var(--token-Hz8pOMy6h);
}
.slotTargetValueinterpretation_positive {
  color: var(--token-pkeGMzUnV);
}
.slotTargetValueinterpretation_valuesIncreased {
  color: var(--token-WXVuPScU2);
}
.freeBox__h9P0 {
  display: block;
  position: relative;
  height: 12px;
  flex-shrink: 0;
}
.freeBox__zpcip {
  position: relative;
  display: flex;
  flex-direction: row;
}
.freeBoxhasInfo__zpcipWlaG {
  margin-right: 35px;
}
.slotTargetText {
  color: var(--token-MNTSAZsue);
}
.freeBox__foMLh {
  display: block;
  position: relative;
  height: 12px;
  flex-shrink: 0;
}
.freeBox__zRtLc {
  position: relative;
  display: flex;
  flex-direction: row;
}
.freeBoxhasInfo__zRtLcWlaG {
  margin-right: 35px;
}
.text {
  position: relative;
}
.slotTargetNumber {
  color: var(--token-MNTSAZsue);
}
.slotTargetNumberhasPhoneNr {
  color: var(--token-b5_F3MaWO);
  font-weight: 500;
}
.slotTargetNumberhasPhoneNr > .__wab_text,
.slotTargetNumberhasPhoneNr > .__wab_slot-string-wrapper,
.slotTargetNumberhasPhoneNr > .__wab_slot > .__wab_text,
.slotTargetNumberhasPhoneNr > .__wab_slot > .__wab_slot-string-wrapper,
.slotTargetNumberhasPhoneNr > .__wab_slot > .__wab_slot > .__wab_text,
.slotTargetNumberhasPhoneNr > .__wab_slot > .__wab_slot > .__wab_slot-string-wrapper,
.slotTargetNumberhasPhoneNr > .__wab_slot > .__wab_slot > .__wab_slot > .__wab_text,
.slotTargetNumberhasPhoneNr > .__wab_slot > .__wab_slot > .__wab_slot > .__wab_slot-string-wrapper {
  text-decoration-line: underline;
}
.link___2PoTw:global(.__wab_instance) {
  position: relative;
}
.freeBox__sFzuE {
  position: relative;
  height: 20px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  display: none;
}
.freeBoxhasButton__sFzuEvp5Wu {
  display: block;
}
.freeBox__pi25S {
  position: relative;
  flex-direction: column;
  align-self: flex-start;
  display: none;
}
.freeBoxhasButton__pi25Svp5Wu {
  display: flex;
}
.button:global(.__wab_instance) {
  position: relative;
  width: auto;
  align-self: auto;
  flex-shrink: 0;
  display: flex;
}
@media (min-width: 768px) {
  .button:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.buttonhasButton:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__qodDx {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  display: block;
}
.svg__xePre {
  position: relative;
  width: 1em;
  height: 1em;
}
.infoButton {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: var(--token-qsn-fdcEb);
  border-bottom-right-radius: 2px;
  bottom: 0;
  right: 0;
  border-top-left-radius: 2px;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 768px) {
  .infoButton {
    cursor: pointer;
  }
}
.infoButtonhasInfo {
  display: flex;
}
.infoButtoninfoIsOpen {
  display: flex;
}
.svg__sjwi1 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-Ajlopyo3r);
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  flex-shrink: 0;
}
.root:hover .svg__sjwi1 {
  color: var(--token-MNTSAZsue);
}
.infoBox {
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transform: translateX(0px) translateY(-100%) translateZ(0px);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  display: none;
}
.infoBoxinfoIsOpen {
  background: #ffffff73;
  height: auto;
  transform: translateX(0px) translateY(0%) translateZ(0px);
  display: flex;
  padding: 16px 20px;
}
.slotTargetInfoText {
  color: var(--token-MNTSAZsue);
}
.text__vubUc {
  height: auto;
  position: relative;
}
