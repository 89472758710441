.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__z5Arm {
  display: flex;
  position: sticky;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  left: 0px;
  top: 0px;
  z-index: 1;
}
@media (min-width: 768px) {
  .freeBox__z5Arm {
    background: linear-gradient(227deg, #f9c9ec 0%, #e1d8f6 100%) 0% 0% / 100% 100% repeat;
    position: relative;
    left: auto;
    top: auto;
    align-items: center;
  }
}
.mainNav:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .mainNav:global(.__wab_instance) {
    position: relative;
  }
}
.dashboardHeader:global(.__wab_instance) {
  position: relative;
}
.freeBox__y0E5V {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 1440px;
  align-self: center;
}
.freeBox__gtReo {
  flex-direction: column;
  display: flex;
  padding: 20px 30px 30px;
}
.freeBox__gtReo > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__gtReo > :global(.__wab_flex-container) > *,
.freeBox__gtReo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gtReo > :global(.__wab_flex-container) > picture > img,
.freeBox__gtReo > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .freeBox__gtReo {
    padding: 40px 50px 50px;
  }
}
@media (min-width: 768px) {
  .freeBox__gtReo > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (min-width: 768px) {
  .freeBox__gtReo > :global(.__wab_flex-container) > *,
  .freeBox__gtReo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__gtReo > :global(.__wab_flex-container) > picture > img,
  .freeBox__gtReo > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
    margin-left: 0px;
    margin-top: 40px;
  }
}
.dashboardInfo:global(.__wab_instance) {
  position: relative;
}
.freeBox__lZt7D {
  flex-direction: column;
  display: flex;
  position: relative;
}
.freeBox__lZt7D > :global(.__wab_flex-container) {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__lZt7D > :global(.__wab_flex-container) > *,
.freeBox__lZt7D > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lZt7D > :global(.__wab_flex-container) > picture > img,
.freeBox__lZt7D > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 20px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .freeBox__lZt7D {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .freeBox__lZt7D > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 25px);
    width: calc(100% + 25px);
    margin-top: calc(0px - 25px);
    height: calc(100% + 25px);
  }
}
@media (min-width: 768px) {
  .freeBox__lZt7D > :global(.__wab_flex-container) > *,
  .freeBox__lZt7D > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__lZt7D > :global(.__wab_flex-container) > picture > img,
  .freeBox__lZt7D > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
    margin-left: 25px;
    margin-top: 25px;
  }
}
.resultCard__nm1Pj:global(.__wab_instance) {
  position: relative;
}
.text__g9Vr {
  height: auto;
  position: relative;
}
.link__aiqpJ:global(.__wab_instance) {
  position: relative;
}
.resultCard__i2A5K:global(.__wab_instance) {
  position: relative;
}
.text__jdaJg {
  height: auto;
  position: relative;
}
.link__pdlsr:global(.__wab_instance) {
  position: relative;
}
.resultCard__xg2Z7:global(.__wab_instance) {
  position: relative;
}
.text___1WspK {
  width: auto;
}
.text___88M4W {
  color: var(--token-MNTSAZsue);
  height: auto;
  position: relative;
}
.link__s2DJx:global(.__wab_instance) {
  position: relative;
}
.resultCard__fKQo:global(.__wab_instance) {
  position: relative;
}
.text__gKtyZ {
  height: auto;
  position: relative;
}
.link__ahKkz:global(.__wab_instance) {
  position: relative;
}
.resultCard__uqOuZ:global(.__wab_instance) {
  position: relative;
}
.text__dltGk {
  height: auto;
  position: relative;
}
.link__nIf7Y:global(.__wab_instance) {
  position: relative;
}
.freeBox___4TlDz {
  display: block;
  position: relative;
}
@media (min-width: 768px) {
  .freeBox___4TlDz {
    width: 300px;
    flex-grow: 1;
    flex-shrink: 0;
    height: 0px;
    display: block;
    position: relative;
  }
}
.freeBox___4Xc8M {
  display: block;
  position: relative;
}
@media (min-width: 768px) {
  .freeBox___4Xc8M {
    width: 300px;
    flex-grow: 1;
    flex-shrink: 0;
    height: 0px;
    display: block;
    position: relative;
  }
}
.freeBox__ahAsA {
  display: block;
  position: relative;
}
@media (min-width: 768px) {
  .freeBox__ahAsA {
    width: 300px;
    flex-grow: 1;
    flex-shrink: 0;
    height: 0px;
    display: block;
    position: relative;
  }
}
.freeBox__acXaw {
  display: block;
  position: relative;
}
@media (min-width: 768px) {
  .freeBox__acXaw {
    width: 300px;
    flex-grow: 1;
    flex-shrink: 0;
    height: 0px;
    display: block;
    position: relative;
  }
}
