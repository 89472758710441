.root {
  display: flex;
  background: var(--token-b5_F3MaWO);
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__osoBw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  padding: 20px 30px;
}
@media (min-width: 768px) {
  .freeBox__osoBw {
    max-width: 1440px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
.freeBoxisScrolled__osoBwk6Bzt {
  padding-top: 10px;
  padding-bottom: 10px;
}
.freeBox__vubLj {
  flex-direction: row;
  display: flex;
  position: relative;
}
.freeBox__vubLj > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__vubLj > :global(.__wab_flex-container) > *,
.freeBox__vubLj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vubLj > :global(.__wab_flex-container) > picture > img,
.freeBox__vubLj > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.backButton:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.backButtonloggedIn:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.backButtonhasBackButton:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.svg__uqRS {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  display: block;
}
.svg__qqtGa {
  position: relative;
  width: 1em;
  height: 1em;
}
.checkpointZuerichLogoWhite {
  background: url('./images/checkpointZuerichLogoWhite.png') center center / cover no-repeat;
  width: 174px;
  height: 16px;
  display: block;
  flex-shrink: 0;
}
.freeBox__pCFaf {
  flex-direction: row;
  display: flex;
  position: relative;
}
.freeBox__pCFaf > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__pCFaf > :global(.__wab_flex-container) > *,
.freeBox__pCFaf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pCFaf > :global(.__wab_flex-container) > picture > img,
.freeBox__pCFaf > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 10px;
}
.languageSwitcher:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.languageSwitcherdoctorView:global(.__wab_instance) {
  display: none;
}
.logoutButton:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.logoutButtonloggedIn:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.logoutButtonhasBackButton:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__iKh8J {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  display: block;
}
.svg__iejeo {
  position: relative;
  width: 1em;
  height: 1em;
}
