.root {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 0;
}
.rootisCard {
  background: none;
}
.textInput {
  font-size: 36px;
  align-self: auto;
  text-align: center;
  background: var(--token-qsn-fdcEb);
  width: 100%;
  color: var(--token-b5_F3MaWO);
  text-transform: capitalize;
  min-width: 0;
  border-radius: 2px;
  border-width: 1px;
  border-style: none;
}
.textInputisCard {
  background: var(--token-OU7EuAwtS);
}
.textInputsize_large {
  font-size: 50px;
  padding: 0px 5px;
}
.root .textInput::placeholder {
  color: var(--token-RCoLngxgW);
}
