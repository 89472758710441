.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.mainNav:global(.__wab_instance) {
  position: relative;
}
.freeBox__ba4MR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 650px;
  align-self: center;
  width: 100%;
  padding: 30px;
}
@media (min-width: 768px) {
  .freeBox__ba4MR {
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
  }
}
.slotTargetTitle {
  font-size: 38px;
  font-weight: 800;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .slotTargetTitle {
    text-align: center;
  }
}
.freeBox__kt1Kl {
  display: block;
  position: relative;
  height: 24px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .slotTargetText {
    text-align: center;
  }
}
.freeBox__vbkpJ {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.passCodeForm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (min-width: 768px) {
  .passCodeForm:global(.__wab_instance) {
    max-width: 530px;
    align-self: center;
  }
}
.freeBox__lnSfu {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.button:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .button:global(.__wab_instance) {
    width: 100%;
    max-width: 300px;
    min-width: 0;
  }
}
.svg__xy8AA {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  display: block;
}
.svg__qcouL {
  position: relative;
  width: 1em;
  height: 1em;
}
.freeBox__ozCfZ {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.freeBoxstate_error__ozCfZFcCvQ {
  height: 15px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .freeBoxstate_error__ozCfZFcCvQ {
    height: 25px;
    flex-shrink: 0;
  }
}
.freeBox__leP8I {
  flex-direction: row;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  box-shadow: inset 0px 0px 0px 1px var(--token-Hk6dmR-vw);
  border-radius: 6px;
}
.freeBox__leP8I > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .freeBox__leP8I {
    top: auto;
    left: auto;
  }
}
.freeBoxstate_error__leP8IFcCvQ {
  top: auto;
  left: auto;
  background: var(--token-GNfbzhOHC);
  width: auto;
  height: auto;
  padding: 15px;
}
.freeBoxstate_error__leP8IFcCvQ > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxstate_error__leP8IFcCvQ > :global(.__wab_flex-container) > *,
.freeBoxstate_error__leP8IFcCvQ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBoxstate_error__leP8IFcCvQ > :global(.__wab_flex-container) > picture > img,
.freeBoxstate_error__leP8IFcCvQ > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 10px;
  margin-top: 0px;
}
.text__iOzx7 {
  width: 100%;
  min-width: 0;
}
.textstate_error__iOzx7FcCvQ {
  color: var(--token-MNTSAZsue);
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: #000;
  display: block;
  flex-shrink: 0;
}
.svgstate_error {
  color: var(--token-7Vqfz2c-_);
}
.freeBox__qKe1M {
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .freeBox__qKe1M {
    height: 60px;
    flex-shrink: 0;
  }
}
