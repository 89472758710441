.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  background: var(--token-b5_F3MaWO);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  padding: 8px 12px;
  border-width: 0px;
  border-style: none;
}
.roothasIcon_iconOnly {
  width: 46px;
  height: 46px;
}
.rootdisabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.rootinactive {
  opacity: 0.4;
}
.rootisOutline {
  box-shadow: inset 0px 0px 0px 2px var(--token-MNTSAZsue);
  background: linear-gradient(var(--token-OU7EuAwtS), var(--token-OU7EuAwtS)), var(--token-b5_F3MaWO);
}
.rootisGhost {
  background: none;
}
.rootisButtonGroup_start {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rootisButtonGroup_end {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.rootisButtonGroup_mid {
  border-radius: 0px;
}
.rootsize_medium {
  height: 40px;
}
.rootsize_small {
  height: 32px;
  padding: 5px 8px 6px;
}
.roottype_blue {
  background: var(--token-_CQhxCwqn);
}
.roottype_white {
  background: var(--token-OU7EuAwtS);
}
.roottype_green {
  background: var(--token-hRLHaBBAu);
}
.roottype_grey {
  background: var(--token-MNTSAZsue);
}
.roothasIcon_iconOnly_size_medium {
  width: 40px;
  height: 40px;
}
.roothasIcon_iconOnly_size_small {
  width: 32px;
  height: 32px;
}
.roottype_blue_isOutline {
  background: #ffffff;
  box-shadow: inset 0px 0px 0px 2px var(--token-_CQhxCwqn);
}
.roottype_grey_isOutline {
  background: var(--token-OU7EuAwtS);
  box-shadow: inset 0px 0px 0px 2px var(--token-MNTSAZsue);
}
.focusOutline {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 0px 0px var(--token-_CQhxCwqn);
  transition-property: box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  border-radius: 2px;
}
.focusOutlineisButtonGroup_start {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.focusOutlineisButtonGroup_end {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.focusOutlineisButtonGroup_mid {
  border-radius: 0px;
}
.root:focus-within .focusOutline {
  box-shadow: 0px 0px 0px 3px var(--token-_CQhxCwqn);
  outline: none;
}
.root .focusOutline___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px var(--token-_CQhxCwqn);
  outline: none;
}
.hoverBackground {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background: var(--token-OU7EuAwtS);
  transition-property: opacity, background;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease-in-out, ease-in-out;
  opacity: 0;
  border-radius: 2px;
}
.hoverBackgroundisButtonGroup_start {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.hoverBackgroundisButtonGroup_end {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.hoverBackgroundisButtonGroup_mid {
  border-radius: 0px;
}
.root:hover .hoverBackground {
  opacity: 0.2;
}
.root:focus-within .hoverBackground {
  outline: none;
}
.root .hoverBackground___focusVisibleWithin {
  outline: none;
}
.root:active .hoverBackground {
  opacity: 0.2;
  background: var(--token-b5_F3MaWO);
}
.hoverBackgroundBlack {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  background: var(--token-b5_F3MaWO);
  opacity: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition-property: opacity, background;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease-in-out, ease-in-out;
  border-radius: 2px;
}
.hoverBackgroundBlackisButtonGroup_start {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.hoverBackgroundBlackisButtonGroup_end {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.hoverBackgroundBlackisButtonGroup_mid {
  border-radius: 0px;
}
.root:hover .hoverBackgroundBlack {
  opacity: 0.1;
}
.content {
  flex-direction: row;
  display: flex;
  position: relative;
}
.content > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.content > :global(.__wab_flex-container) > *,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content > :global(.__wab_flex-container) > picture > img,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 10px;
}
.contentsize_medium > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.contentsize_medium > :global(.__wab_flex-container) > *,
.contentsize_medium > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.contentsize_medium > :global(.__wab_flex-container) > picture > img,
.contentsize_medium > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
  margin-top: 0px;
}
.contentsize_small > :global(.__wab_flex-container) {
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.contentsize_small > :global(.__wab_flex-container) > *,
.contentsize_small > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.contentsize_small > :global(.__wab_flex-container) > picture > img,
.contentsize_small > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 6px;
  margin-top: 0px;
}
.slotTargetIconStart {
  font-size: 22px;
  color: var(--token-OU7EuAwtS);
}
.slotTargetIconStartisGhost {
  color: var(--token-Fsnegb8P1);
}
.slotTargetIconStartsize_medium {
  font-size: 16px;
}
.slotTargetIconStartsize_small {
  font-size: 16px;
}
.slotTargetIconStarttype_white {
  color: var(--token-b5_F3MaWO);
}
.slotTargetIconStarttype_grey_isOutline {
  color: var(--token-MNTSAZsue);
}
.svg__mG1E {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  display: block;
}
.slotTargetText {
  font-size: 22px;
  font-weight: 700;
  color: var(--token-OU7EuAwtS);
}
.slotTargetTextisOutline {
  color: var(--token-b5_F3MaWO);
}
.slotTargetTextisGhost {
  color: var(--token-Fsnegb8P1);
}
.slotTargetTextsize_medium {
  font-size: 17px;
}
.slotTargetTextsize_small {
  font-size: 15px;
}
.slotTargetTexttype_white {
  color: var(--token-b5_F3MaWO);
}
.slotTargetTexttype_blue_isOutline {
  color: var(--token-_CQhxCwqn);
}
.slotTargetTexttype_grey_isOutline {
  color: var(--token-MNTSAZsue);
}
.slotTargetIconEnd {
  font-size: 22px;
  color: var(--token-OU7EuAwtS);
}
.slotTargetIconEndisGhost {
  color: var(--token-Fsnegb8P1);
}
.slotTargetIconEndsize_medium {
  font-size: 16px;
}
.slotTargetIconEndsize_small {
  font-size: 16px;
}
.slotTargetIconEndtype_white {
  color: var(--token-b5_F3MaWO);
}
.slotTargetIconEndtype_grey_isOutline {
  color: var(--token-MNTSAZsue);
}
.svg__q2Wmr {
  position: relative;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
